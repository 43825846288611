import React, { Component, Fragment } from "react";
import Navbar from "../../UI/Navbar";
import balleste from "../../../assets/Sballeste headshot.png";
import savrnoch from "../../../assets/John-headshot.jpg";
import kelly from "../../../assets/Kduncan Headshot.jpg";
import megan from "../../../assets/Megan.jpg";
import soderman from "../../../assets/Michael-headshot.jpg";
import perkins from "../../../assets/Perkins.jpg";
import jenn from "../../../assets/Jenn.png";
import nudson from "../../../assets/Nudson.png";

class ManagementTeam extends Component {
  render() {
    return (
      <Fragment>
        <Navbar></Navbar>
        <div className="container">
          <div
            className="content-wrapper-no-flex font-display text-base"
            style={{ backgroundColor: "rgba(112,112,112,0.05)" }}
          >
            <div className="team-member-wrapper">
              <div className="img-wrapper">
                <img
                  alt="savrnoch"
                  src={savrnoch}
                  style={{
                    objectFit: "contain",
                    display: "flex",
                    alignSelf: "flex-start",
                    marginTop: "10px",
                  }}
                />
              </div>
              <div className="team-member-description">
                <div
                  className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max"
                >
                  John T. Savrnoch, District Attorney
                </div>
                <p className="mt-4">
                  John Savrnoch was elected District Attorney of Santa Barbara
                  County in June 2022 and assumed office on January 2nd, 2023.
                  John Savrnoch began his legal career over 30 years ago. Prior
                  to coming to Santa Barbara County, John was a prosecutor for
                  the Fresno County District Attorney’s Office for 23 years
                  where he represented abused children, and prosecuted insurance
                  fraud and other serious financial crimes. For John’s last ten
                  years in Fresno, he served in leadership roles including
                  supervising the Financial Crimes and Consumer Protection Unit,
                  supervising the Misdemeanor Prosecution Unit, and serving as
                  an Assistant District Attorney. John also held the office of
                  Chief Assistant District Attorney.
                </p>
                <p className="mt-4">
                  John joined the Santa Barbara County District Attorney’s
                  Office in January 2015 as a Deputy District Attorney in
                  Lompoc. He was promoted to Chief Deputy District Attorney in
                  2016. Most recently, John served as the Assistant District
                  Attorney, where he oversaw all South County attorneys and
                  cases. John led the office through several major innovations
                  and initiatives, and helped design and implement positive
                  community programs. Throughout his career, John has worked to
                  implement alternative justice programs designed to divert
                  individuals from the criminal justice system and help
                  offenders understand the consequences of their actions in
                  order to reduce recidivism and protect public safety.
                </p>
                <p className="mt-4">
                  He received a Bachelor of Science and a Master of Business
                  Administration from Marquette University in Milwaukee,
                  Wisconsin. John attended law school at the University of
                  Wisconsin, where he received his Juris Doctor in 1990.
                </p>
              </div>
              {/* <div className="team-member-wrapper">
                                <div className="img-wrapper">
                                    <img alt="savrnoch"
                                    src={savrnoch}
                                    style={{objectFit: 'contain', display: 'flex', alignSelf: 'flex-start', marginTop: '10px'}}
                                    />
                                </div>
                                
                                <div className="team-member-description">
                                <div className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max">
                                    John Savrnoch, Assistant District Attorney
                                    </div>
                                    <p className="mt-4">John Savrnoch has been a Deputy District Attorney for the Santa Barbara County District Attorney’s Office assigned to the Lompoc Division since January of 2015.  Prior to coming to Santa Barbara County, John was an attorney for the Fresno County District Attorney’s Office for almost 23 years.  John’s career in Fresno included representing abused children, prosecuting insurance fraud, and prosecuting serious financial crimes before spending the last decade as a supervisor/administrator.  John’s administrative assignments in Fresno County included supervising the financial crimes and consumer protection unit, supervising the misdemeanor prosecution unit, serving as an Assistant District Attorney, and serving as the Chief Assistant District Attorney.</p>
                                    <p className="mt-4">John received both a Bachelor of Science and a Master of Business Administration from Marquette University in Milwaukee, Wisconsin.  John attended law school at the University of Wisconsin where he received his Juris Doctor in 1990.</p>
                                    <p className="mt-4">In March 2016 John was appointed to serve as a Chief Deputy District Attorney by Santa Barbara County District Attorney Joyce Dudley. John now serves as the Chief Deputy District Attorney for the Santa Barbara office.</p>
                                    
                                </div>
                            </div> */}
            </div>
            <div className="team-member-wrapper">
              <div className="img-wrapper">
                <img
                  alt="kelly"
                  src={kelly}
                  style={{
                    objectFit: "contain",
                    display: "flex",
                    alignSelf: "flex-start",
                    marginTop: "10px",
                  }}
                />
              </div>

              <div className="team-member-description">
                <div
                  className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max"
                >
                  Kelly Duncan, Assistant District Attorney
                </div>
                <p className="mt-4">
                  Kelly is a graduate of Pepperdine University where she earned
                  a Bachelor’s Degree in English Literature and a Juris Doctor
                  from Pepperdine University School of Law.
                </p>
                <p className="mt-4">
                  Kelly began her career as a Deputy Attorney for the California
                  Department of Transportation where she litigated civil cases
                  involving allegations of dangerous conditions of public
                  property, employment law, inverse condemnation, and eminent
                  domain. In 2004, Kelly joined the Santa Barbara Office of
                  County Counsel where she represented the Sheriff and District
                  Attorney in civil rights cases and employment litigation.
                </p>
                <p className="mt-4">
                  Kelly was appointed to the position of Chief Deputy District
                  Attorney in 2012 by District Attorney Joyce Dudley, and was
                  appointed to Assistant District Attorney in 2023 by District
                  Attorney John Savrnoch. Kelly was Santa Barbara County’s
                  prosecutor in the successful multi-county prosecution of the
                  Golden State Killer, who committed 13 murders and over 50
                  sexual assaults in 11 different jurisdictions throughout
                  California. In 2022 Kelly successfully prosecuted a 10
                  defendant MS-13 gang homicide and conspiracy case involving 24
                  victims. This case was the largest gang trial in California’s
                  history in terms of number of defendants and number and
                  seriousness of charges (50 counts including homicide,
                  attempted homicide, and gang conspiracy.)
                </p>
                <p className="mt-4">
                  As Assistant District Attorney, Kelly’s primary
                  responsibilities include the administration of the office, as
                  well as supervision of the Homicide and Gang Units in the
                  Santa Barbara Office. She is also responsible for the office’s
                  Brady and ethics training and policies, oversees the Officer
                  Involved Incident Team, and established the Conviction Review
                  Unit.
                </p>
              </div>
            </div>
            {/* <div className="team-member-wrapper">
                                <div className="img-wrapper">
                                    <img alt="balleste"
                                    src={balleste}
                                    style={{objectFit: 'contain', display: 'flex', alignSelf: 'flex-start', marginTop: '10px'}}
                                    />  
                                </div>
                                <div className="team-member-description">
                                     <div className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max">
                                    Sonia Balleste, Chief Deputy District Attorney, North County
                                    </div>
                                    <p className="mt-4">Sonia is a graduate of the University of California Irvine where she earned a degree in Social Ecology. She received her juris doctor from Thomas Jefferson School of Law in San Diego.</p>
                                    <p className="mt-4">Sonia began her career as Deputy District Attorney in Southern California where she prosecuted violent crime cases. Sonia specialized in homicide trials and investigations. She successfully litigated capital cases, gang cases and domestic violence cases.  Sonia is also proficient in forensic investigation and litigation including her experience in Rapid DNA and genetic genealogy investigations.</p>
                                    <p className="mt-4">Sonia joined the Santa Barbara County District Attorney's office in 2019 prosecuting arson, gang and homicide cases. In May of 2020 she was promoted to the position of Chief Deputy.</p>
                                    <p className="mt-4">As Chief Deputy, Sonia's primary responsivities include the supervision of all Deputy District Attorneys assigned to the Santa Maria Office, she also acts as the Rapid DNA liaison and is in charge of cold case investigations.</p>
                                </div>
                                
                            </div> */}
            <div className="team-member-wrapper">
              <div className="img-wrapper">
                <img
                  alt="Nudson"
                  src={nudson}
                  style={{
                    objectFit: "contain",
                    display: "flex",
                    alignSelf: "flex-start",
                    marginTop: "10px",
                  }}
                />
              </div>
              <div className="team-member-description">
                <div
                  className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max"
                >
                  Anne Nudson, Chief Deputy District Attorney, North County
                </div>
                <p className="mt-4">
                  Anne is a graduate of University of California, Santa Cruz
                  where she earned a degree in American Politics and a degree in
                  Modern Literature. She received her juris doctor from
                  Georgetown Law School in Washington, D.C.
                </p>
                <p className="mt-4">
                  Anne began her career as a Deputy District Attorney in Santa
                  Barbara County in 2010. She has prosecuted a variety of cases
                  including narcotics, domestic violence, sexual assault,
                  homicide, and child abuse. In 2018, Anne spearheaded the
                  creation of a countywide Domestic Violence Task Force to
                  address growing domestic violence in our community.{" "}
                </p>
                <p className="mt-4">
                  Anne has been a DDA Supervisor since 2019, and she specialized
                  in training new hires and the misdemeanor and general felony
                  teams. Anne has also supervised the Vulnerable Victims Unit.
                  In April of 2023, Anne was promoted to the position of Chief
                  Deputy.{" "}
                </p>
                <p className="mt-4">
                  As Chief Deputy, Anne’s primary responsibilities include
                  supervising the misdemeanor and juvenile teams countywide,
                  along with managing countywide special technology projects.
                </p>
              </div>
            </div>
            <div className="team-member-wrapper">
              <div className="img-wrapper">
                <img
                  alt="jenn"
                  src={jenn}
                  style={{
                    objectFit: "contain",
                    display: "flex",
                    alignSelf: "flex-start",
                    marginTop: "10px",
                    maxHeight: "270px",
                    width: "180px",
                  }}
                />
              </div>
              <div className="team-member-description">
                <div
                  className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max"
                >
                  Jennifer Karapetian, Chief Deputy District Attorney, South
                  County
                </div>
                <p className="mt-4">
                  Jennifer Karapetian earned her Bachelor of Arts in Political
                  Science from California State University, Fullerton and
                  obtained her law degree from Hofstra Law School in New York.
                </p>
                <p className="mt-4">
                  Jennifer began her career with the Santa Barbara County
                  District Attorney's Office in Santa Maria in 2010, handling a
                  wide array of cases, from misdemeanors to felonies, including
                  violent crimes, homicides, and gang-related offenses.
                  Additionally, she worked in juvenile and collaborative courts
                  and participated in innovative programs, such as juvenile
                  restorative justice and teen court. Jennifer's commitment to
                  education and community involvement led her to coach and
                  preside over mock trial and teach at several colleges and law
                  schools throughout the state.
                </p>
                <p className="mt-4">
                  In 2012, Jennifer started to specialize in cases involving
                  child abuse, molestation, domestic violence, sexual assault,
                  and elder abuse. Notably, she played a pivotal role in the
                  establishment of the Santa Barbara County Human Trafficking
                  Task Force in 2014. Relocating to the Santa Barbara office in
                  2016, she continued her dedicated work with vulnerable victims
                  and assumed the role of supervising the Vulnerable Victim's
                  Unit in 2020.{" "}
                </p>
                <p className="mt-4">
                  As Chief Deputy, Jennifer manages criminal attorneys in the
                  Santa Barbara office and provides countywide oversight for
                  collaborative courts, the Neighborhood Restorative Justice
                  Program, and criminal filings.{" "}
                </p>
              </div>
            </div>
            <div className="team-member-wrapper">
              <div className="img-wrapper">
                <img
                  alt="perkins"
                  src={perkins}
                  style={{
                    objectFit: "contain",
                    display: "flex",
                    alignSelf: "flex-start",
                    marginTop: "10px",
                  }}
                />
              </div>

              <div className="team-member-description">
                <div
                  className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max"
                >
                  Kristina Perkins, Chief Criminal Investigator
                </div>
                <p className="mt-4">
                  Kristina Perkins was appointed to the position of Chief
                  investigator of the Santa Barbara County District Attorney’s
                  Office in December 2021.
                </p>
                <p className="mt-4">
                  Kristina began her law enforcement career in 2007 as a police
                  officer for the University of California, Santa Barbara Police
                  Department. In 2013, Kristina began her employment at the
                  Santa Barbara County District Attorney’s Office as an
                  investigator where she worked assignments in the Welfare Fraud
                  Unit, Cannabis Compliance Team, Arson, Environmental Crimes,
                  and Consumer Fraud. In 2020, Kristina was promoted to the
                  position of Commander of the Special Investigations Unit and
                  Lompoc Criminal Investigative Team.
                </p>
                <p className="mt-4">
                  Kristina was born in Santa Barbara and raised in Ojai, CA. She
                  attended Santa Barbara City College, California State
                  University, San Marcos and California State University,
                  Northridge. She possesses an Associate’s Degree in
                  Administration of Justice, a Bachelor’s Degree in Political
                  Science and a Master’s Degree in Public Administration.{" "}
                </p>
                <p className="mt-4">
                  Chief Perkins is the first woman in the history of the Santa
                  Barbara County District Attorney’s office to be named Chief.
                </p>
              </div>
            </div>

            {/* <div className="team-member-wrapper">
                                <div className="img-wrapper">
                                    <img alt="nicola"
                                    src={nicola}
                                    style={{objectFit: 'contain', display: 'flex', alignSelf: 'flex-start', marginTop: '10px'}}
                                    />
                                </div>
                                <div className="team-member-description">
                                <div className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max">
                                    Mag Nicola, Chief Deputy District Attorney, North County
                                    </div>
                                    <p className="mt-4">Mag Nicola grew up in San Diego where he attended the University of California, San Diego, and California Western School of Law.  He earned his law degree in 1993.</p>
                                    <p className="mt-4">Mag began his career in public service with the San Diego County District Attorney’s Office in 1993 as a contract Deputy District Attorney with their Major Narcotics Prosecution Unit, before joining the Santa Barbara County District Attorney’s Office in 1994.  Over his career, he has successfully prosecuted all types of serious crimes including murders, kidnappings, gang-violence, major narcotics, child abuse and sexual assaults.  Mag has twice received the Santa Maria Elk’s Law Enforcement Appreciation award for Outstanding Performance, was recognized as Deputy District Attorney of the Year in 1998 and 2005, and is a recipient of the H. Thomas Guerry Award for Outstanding Performance. Mag was appointed to the position of Chief Deputy District Attorney in August 2013.</p>
                                    <p className="mt-4">Mag supervises the attorneys in the Lompoc and Santa Maria Juvenile offices, oversees all administrative operations for the north county offices and serves as the liaison for law enforcement, probation, courts, and other state and federal allied agencies.  He is the District Attorney’s countywide representative for Collaborative Treatment Courts, Human Trafficking, AB 109, and the Juvenile Justice Coordinating Council. </p>
                                
                                </div>
                            </div> */}
            <div className="team-member-wrapper">
              <div className="img-wrapper">
                <img
                  alt="megan"
                  src={megan}
                  style={{
                    objectFit: "contain",
                    display: "flex",
                    alignSelf: "flex-start",
                    marginTop: "10px",
                  }}
                />
              </div>
              <div className="team-member-description">
                <div
                  className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max"
                >
                  Megan Rheinschild, Victim-Witness Assistance Program Director
                </div>
                <p className="mt-4">
                  Megan Rheinschild graduated from the University of California,
                  Santa Barbara and studied at the National Autonomous
                  University in Mexico City receiving her degree in Sociology
                  and Spanish.
                </p>
                <p className="mt-4">
                  Megan has been employed in the Santa Barbara County District
                  Attorney’s Office Victim Witness Assistance Program since 1994
                  and has been the Director of the Countywide Program since
                  1997. She is responsible for oversight of a program whose
                  staff annually serves over 3,500 victims of violent crime and
                  their family members countywide.
                </p>
                <p className="mt-4">
                  She oversees a range of victim service programs including the
                  Human Trafficking, Elder and Dependent Abuse Units, the Victim
                  Compensation and Restitution Recovery Contracts with the State
                  of California. She also has administrative oversight of the
                  Countywide Sexual Assault ResponseTeam.
                </p>
                <p className="mt-4">
                  In addition to her local responsibilities, Megan is on the
                  Board of the California Crime Victim Assistance Association
                  and is Chair of the Statewide Training Committee.
                </p>
              </div>
            </div>
            {/* <div className="team-member-wrapper">
                                <div className="img-wrapper">
                                    <img alt="ross"
                                        src={ross}
                                        style={{objectFit: 'contain', display: 'flex', alignSelf: 'flex-start', marginTop: '10px'}}
                                        />
                                </div>
                                
                                <div className="team-member-description">
                                <div className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max">
                                    Barbara Ross, Executive Assistant to Joyce Dudley
                                    </div>
                                    <p className="mt-4">Barbara Ross joined the District Attorney's Office as Executive Assistant to District Attorney Joyce Dudley in December 2019.  She also provides administrative support for the Executive Staff.  Prior to joining the DA Team, Barbara was Executive Assistant to developer and philanthropist Michael Towbes for over 22 years.  She has a BA in Political Science from Allegheny College.  Barbara moved from the east coast (Washington, DC) to Southern California (Santa Barbara) in 1995.</p>
                                    <p className="mt-4">Barbara enjoys cooking and has become a very proficient sourdough bread maker.  She has a passion for the nonprofit community in Santa Barbara and is an active volunteer at the Santa Barbara Zoo.</p>
                                    
                                </div>
                            </div> */}

            <div className="team-member-wrapper">
              <div className="img-wrapper">
                <img
                  alt="soderman"
                  src={soderman}
                  style={{
                    objectFit: "contain",
                    display: "flex",
                    alignSelf: "flex-start",
                    marginTop: "10px",
                  }}
                />
              </div>

              <div className="team-member-description">
                <div
                  className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max"
                >
                  Michael Soderman, Chief Financial &amp; Administrative Officer
                </div>
                <p className="mt-4">
                  Michael Soderman has been an integral part of the Santa
                  Barbara County District Attorney’s Office since October 2015.
                  He was brought on as Business Manager and has served in his
                  current role as Chief Financial and Administrative Officer
                  since 2017. Michael is responsible for all Finance &amp;
                  Accounting, Human Resources, and Legal Office Support.
                </p>
                <p className="mt-4">
                  Prior to joining the District Attorney’s Office, Michael
                  served as Accounting Supervisor for the Santa Barbara County
                  Department of Behavior Wellness. Michael’s work history also
                  includes managerial accounting experience within the private
                  sector. Michael’s diverse background in both sectors provides
                  him with a unique vision to navigate the District Attorney’s
                  Office toward innovative and effective business solutions. He
                  has been instrumental in developing and implementing financial
                  and administrative improvements.
                </p>
                <p className="mt-4">
                  Michael grew up in Santa Barbara County and moved to Northern
                  California to attend California State University, Sacramento,
                  where he earned his Bachelor’s Degree in Business
                  Administration with an emphasis in Accounting. Michael feels
                  fortunate to be able to bring his accounting and finance skill
                  set back to his hometown where he can be a part of Santa
                  Barbara County public service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ManagementTeam;
